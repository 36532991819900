import RenLink from "components/shared/RenLink";
import Image from "components/media/image";
import Time from "components/icons/time";

export const CardElearning = ({ entry }): React.ReactElement => {
  return (
    <RenLink href={entry}>
      <div className="card-elearning o-card-listing__item">
        <Image
          image={entry.image}
          alt={entry.image.alt}
          interceptBuilder={builder => builder.width(150)}
        />
        <p className="o-label-uppercase u-text--grey o-grid__item--margin-top u-text--black">
          E-læring
        </p>
        <h3>{entry.title}</h3>
        <div className="time-and-price">
          <Time />{" "}
          <span className="minutes o-label-uppercase">
            {entry.duration} minutter
          </span>
          {entry.free && <p className="free o-label-uppercase">Gratis</p>}
          {!entry.free && <p className="price">Pris kr {entry.price},-</p>}
        </div>
      </div>
    </RenLink>
  );
};

export const CardListElearning = ({
  entries,
  showTitle = false,
}): React.ReactElement => {
  return (
    <div className="o-wrapper">
      {showTitle && <h2 className="u-padding-top-bottom--large">E-læring</h2>}
      <div className={"o-card-listing"}>
        {entries.map(entry => (
          <CardElearning entry={entry} key={entry._id} />
        ))}
      </div>
    </div>
  );
};
