/** Check if a course or an event is free to attend or not
 *
 */
export const eventHasEntranceFee = priceString => {
  // No numbers in price => "gratis"
  const regexPattern = /\d/g;
  return regexPattern.test(priceString);
};

/**
 * Determines if an course or an event has "ledig" tickets, if recording is availble or not and if an event is "Ferdig"
 * @param entry An course or event
 * @returns A string with the status of the event
 */
export const setEntryStatus = entry => {
  const todaysDate = new Date().getTime();
  const courseDate = new Date(entry.courseDate).getTime();
  const courseEndDate = new Date(entry.endDate).getTime();

  const isFutureEvent = courseDate > todaysDate;
  const isOngoingEvent = courseDate < todaysDate && courseEndDate > todaysDate;

  if (isOngoingEvent) {
    return "Pågår";
  }

  if (isFutureEvent) {
    if (entry.status) {
      return entry.status;
    } else {
      return "Ledig";
    }
  } else {
    if (entry.recordingAvailable) {
      if (eventHasEntranceFee(entry.price)) {
        return "Kjøp opptak";
      } else {
        return "Se opptak";
      }
    } else {
      return "Ferdig";
    }
  }
};
