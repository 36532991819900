const ArrowRight = props => {
  const { className = "" } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 24C4 22.8954 4.89543 22 6 22H42C43.1046 22 44 22.8954 44 24C44 25.1046 43.1046 26 42 26H6C4.89543 26 4 25.1046 4 24Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5858 10.5858C29.3668 9.80474 30.6332 9.80474 31.4142 10.5858L43.4142 22.5858C44.1953 23.3668 44.1953 24.6332 43.4142 25.4142L31.4142 37.4142C30.6332 38.1953 29.3668 38.1953 28.5858 37.4142C27.8047 36.6332 27.8047 35.3668 28.5858 34.5858L39.1716 24L28.5858 13.4142C27.8047 12.6332 27.8047 11.3668 28.5858 10.5858Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRight;
