import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import { prettyDate } from "../../helpers/dates";
import { setEntryStatus } from "./courseUtils";
import { useState, useEffect } from "react";
import RadiobuttonOn from "components/icons/radiobutton-on";

const RelevantCourseLink = ({ entry }) => {
  /**
   * If the "price" of an event contains a digit, the event has an entry fee and it not free
   * @param priceString The "price"-string from an event
   * @returns Boolean true if an event has an entrance fee
   */
  const [isHighlighted, setIsHighlighted] = useState(highlightCourse(entry));

  const [status, setStatus] = useState(entry.status);

  const { title, courseDate, courseLocation = "" } = entry;

  useEffect(() => {
    setStatus(setEntryStatus(entry));
  }, [entry]);

  return (
    <RenLink href={entry}>
      <div
        className={`c-relevant-course-link ${isHighlighted ? "c-highlighted-course" : ""}`}
      >
        {title && (
          <p className="c-relevant-course-link__title">
            {isHighlighted && (
              <span className="c-relevant-course-link__live">
                <RadiobuttonOn />
              </span>
            )}
            {title}
          </p>
        )}

        <p className="c-relevant-course-link__date">
          <time className="c-relevant-course-link__date">
            {courseDate && prettyDate({ date: courseDate })}
          </time>
        </p>

        <p className="c-relevant-course-link__location">
          {courseLocation && courseLocation}
        </p>

        <p className="c-relevant-course-link__status">{status && status}</p>

        <section className="c-relevant-course-link__arrow">
          <ArrowIcon />
        </section>
      </div>
      <div className="c-relevant-course-link__underline" />
    </RenLink>
  );
};

function highlightCourse(entry): boolean {
  const dateNow = new Date();

  if (new Date(entry.endDate) < dateNow) {
    return false;
  }

  return new Date(entry.courseDate) < dateNow;
}

export default RelevantCourseLink;
