import Menu from "components/layout/menu";
import PageMeta from "components/layout/pageMeta";
import { client } from "helpers/sanity";
import { globalQueries, portableTextQuery } from "helpers/sanityQueries";
import Footer from "components/layout/footer";
import Header from "components/layout/header";
import { GetStaticProps } from "next";
import { PreviewRequirements } from "pages/preview";
import RelevantCourses from "components/course/relevant.courses";
import { CardListElearning } from "components/card/card.elearning";
import LinkWithArrow from "components/shared/link-with-arrow";

export const BUMP_TO_HISTORY_DELAY = 60 * 60 * 5; // 5 hours, until we move the event to history page

const EventsPage = props => {
  const {
    doc,
    settings,
    doc: {
      title = "Ingen tittel",
      lede,
      formSwitch,
      portableText,
      featuredCourses,
    },
    menu,
    conferences,
    courses,
    contact,
  } = props;

  return (
    <>
      <PageMeta doc={doc} settings={settings} />
      <Menu menu={menu} title={title} />
      <main className="u-bg--grey-sky">
        <Header
          type="event"
          lede={lede}
          featuredCourses={featuredCourses}
          displayContactForm={formSwitch}
        />
        <section className="o-wrapper  u-padding-top-bottom--large">
          <RelevantCourses entries={conferences} portableText={portableText} />
          <LinkWithArrow
            href={"/arrangement/tidligere"}
            title={"Se tidligere arrangementer"}
            direction={"right"}
          />
        </section>

        {courses && courses.length > 0 && (
          <section className="o-wrapper u-padding-top-bottom--large">
            <CardListElearning entries={courses} showTitle={true} />
            <LinkWithArrow
              href={"/arrangement/ekurs"}
              title={"Se alle e-læringskurs"}
              direction={"right"}
            />
          </section>
        )}
      </main>
      <Footer contact={contact} settings={settings} />
    </>
  );
};

export const getStaticProps: GetStaticProps<PreviewRequirements> = async () => {
  const groqQuery = {
    doc: `*[_type == "eventsPage" && _id=="eventsPage" && !(_id in path('drafts.*'))][0]
      {
        title,
        lede,
        ${portableTextQuery},
        "featuredCourses": featuredCourses[]->{_id, _type, slug, title, courseLocation, courseDate, endDate, eventType }
      }`,
    ...globalQueries,
    conferences: `*[_type == "conference" && showConference == true && !(_id in path('drafts.*')) && dateTime(endDate) > dateTime(now()) - ${BUMP_TO_HISTORY_DELAY}]
      {_id, _type, slug, price, recordingAvailable, title, courseLocation, courseDate, endDate, eventType, status}`,
    courses: `*[_type== "eLearningCoursePage" && !(_id in path('drafts.*')) && listed == true]{...}`,
  };

  const result = await client.fetch(groqQuery);
  if (!result || !result.doc || Object.keys(result.doc).length === 0) {
    return { notFound: true };
  }
  return {
    props: { ...result, groqQuery },
    revalidate: 3600, // 1 hour
  };
};

export default EventsPage;
