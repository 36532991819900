import RenLink from "./RenLink";
import ArrowRight from "components/icons/arrow-right";

type Props = {
  href: string;
  title: string;
  direction: "left" | "right";
};

const LinkWithArrow = ({
  href,
  title,
  direction,
}: Props): React.ReactElement => (
  <p
    className={`o-wrapper u-link-with-arrow ${
      direction == "left" ? "u-rotate" : ""
    }`}
  >
    {direction == "left" && <ArrowRight />}
    <RenLink href={href} classes={"with-svg"}>
      {title}
    </RenLink>{" "}
    {direction == "right" && <ArrowRight />}
  </p>
);

export default LinkWithArrow;
