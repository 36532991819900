const Time = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66699 26C3.66699 14.7704 12.7704 5.66701 24 5.66701C35.2296 5.66701 44.333 14.7704 44.333 26C44.333 37.2296 35.2296 46.333 24 46.333C12.7704 46.333 3.66699 37.2296 3.66699 26ZM24 9.66701C14.9796 9.66701 7.66699 16.9796 7.66699 26C7.66699 35.0204 14.9796 42.333 24 42.333C33.0204 42.333 40.333 35.0204 40.333 26C40.333 16.9796 33.0204 9.66701 24 9.66701Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7604 13.354C24.865 13.3542 25.7603 14.2498 25.7601 15.3544L25.7582 25.5337L32.9453 32.7208C33.7263 33.5018 33.7263 34.7682 32.9453 35.5492C32.1642 36.3303 30.8979 36.3303 30.1168 35.5492L22.3438 27.7762C21.9687 27.4011 21.758 26.8922 21.7581 26.3616L21.7601 15.3536C21.7603 14.2491 22.6559 13.3538 23.7604 13.354Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6275 2.83753C13.2695 3.73635 13.0613 4.98545 12.1625 5.62747L5.16251 10.6275C4.26368 11.2695 3.01458 11.0613 2.37257 10.1625C1.73055 9.26365 1.93873 8.01455 2.83756 7.37254L9.83756 2.37254C10.7364 1.73052 11.9855 1.9387 12.6275 2.83753Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.3726 2.83753C36.0146 1.9387 37.2637 1.73052 38.1625 2.37254L45.1625 7.37254C46.0613 8.01455 46.2695 9.26365 45.6275 10.1625C44.9855 11.0613 43.7364 11.2695 42.8376 10.6275L35.8376 5.62747C34.9387 4.98545 34.7305 3.73635 35.3726 2.83753Z"
      fill="currentColor"
    />
  </svg>
);
export default Time;
