const RadiobuttonOn = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 34.333C26.7667 34.333 29.1253 33.3577 31.076 31.407C33.0267 29.4563 34.0013 27.0983 34 24.333C34 21.5663 33.0247 19.2077 31.074 17.257C29.1233 15.3063 26.7653 14.3317 24 14.333C21.2333 14.333 18.8747 15.3083 16.924 17.259C14.9733 19.2097 13.9987 21.5677 14 24.333C14 27.0997 14.9753 29.4583 16.926 31.409C18.8767 33.3597 21.2347 34.3343 24 34.333ZM24 44.333C21.2333 44.333 18.6333 43.8077 16.2 42.757C13.7667 41.7063 11.65 40.2817 9.85 38.483C8.05 36.683 6.62533 34.5663 5.576 32.133C4.52667 29.6997 4.00133 27.0997 4 24.333C4 21.5663 4.52533 18.9663 5.576 16.533C6.62667 14.0997 8.05133 11.983 9.85 10.183C11.65 8.38301 13.7667 6.95834 16.2 5.90901C18.6333 4.85967 21.2333 4.33434 24 4.33301C26.7667 4.33301 29.3667 4.85834 31.8 5.90901C34.2333 6.95967 36.35 8.38434 38.15 10.183C39.95 11.983 41.3753 14.0997 42.426 16.533C43.4767 18.9663 44.0013 21.5663 44 24.333C44 27.0997 43.4747 29.6997 42.424 32.133C41.3733 34.5663 39.9487 36.683 38.15 38.483C36.35 40.283 34.2333 41.7083 31.8 42.759C29.3667 43.8097 26.7667 44.3343 24 44.333ZM24 40.333C28.4667 40.333 32.25 38.783 35.35 35.683C38.45 32.583 40 28.7997 40 24.333C40 19.8663 38.45 16.083 35.35 12.983C32.25 9.88301 28.4667 8.33301 24 8.33301C19.5333 8.33301 15.75 9.88301 12.65 12.983C9.55 16.083 8 19.8663 8 24.333C8 28.7997 9.55 32.583 12.65 35.683C15.75 38.783 19.5333 40.333 24 40.333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RadiobuttonOn;
